<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder">{{ $t('ordered_passes.title') }}
          <span class="text-muted pt-2 font-size-sm d-block">{{ $t('ordered_passes.count_pass', {n: passesTotalCount}) }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <button @click="showPassRequestModal = true" class="btn btn-light btn-hover-primary mr-3">
          <font-awesome-icon class="mr-1" icon="plus"></font-awesome-icon>
          {{ $t('ordered_passes.new_order_btn') }}
        </button>
      </div>
    </div>
    <div v-visibility-change="visibilityChange" class="card-body" >
      <div class="row">
          <div class="col-lg-9 col-xl-8 py-3">
            <form>
              <div class="form-row">
                <div class="col">
                  <label>{{ $t('ordered_passes.type_filter') }}</label>
                  <select class="form-control" v-model="filter.type">
                    <option value="">{{$t('common.all')}}</option>
                    <option v-for="type in passTypeList" :value="type.id">{{ type.name }}</option>
                  </select>
                </div>
                <div class="col">
                    <label>{{ $t('ordered_passes.status_filter') }}</label>
                    <select class="form-control" v-model="filter.status">
                      <option value="">{{$t('common.all')}}</option>
                      <option v-for="status in statuses" :value="status.id">{{ $t(`ordered_passes.status.${status.name}`) }}</option>
                    </select>
                </div>
                <div class="col">
                  <label>{{ $t('ordered_passes.period_filter') }}</label>
                  <date-range-picker
                      ref="picker"
                      :show-dropdowns="true"
                      :linked-calendars="true"
                      :close-on-esc="true"
                      :ranges="false"
                      v-model="dateRange"
                      @update="updateDataRange"
                      control-container-class=""
                      :locale-data="$t('data-range-piker')"
                  >
                    <div slot="input" slot-scope="data" style="min-width:250px;" class="input-group">
                      <div v-if="data.startDate" type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2">{{ data.startDate | formatDate }} - {{ data.endDate | formatDate }}</div>
                      <div v-else type="text" class="form-control"
                           placeholder="Recipient's username" aria-label="Recipient's username"
                           aria-describedby="basic-addon2"></div>
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><i class="flaticon-event-calendar-symbol"></i></span>
                      </div>
                    </div>
                    <div slot="footer" slot-scope="data" class="drp-buttons">
                      <span data-v-4f8eb193="" class="drp-selected">{{data.rangeText}}</span>
                      <button @click="clickDataRangeCencel(data)" type="button"
                              class="cancelBtn btn btn-sm btn-secondary">{{data.locale.cancelLabel}} </button>
                      <button @click="data.clickApply" v-if="!data.in_selection" type="button"
                              class="applyBtn btn btn-sm btn-success">{{data.locale.applyLabel}} </button>
                    </div>
                  </date-range-picker>
                </div>
              </div>
            </form>
          </div>
        </div>
      <!--begin: Datatable-->
      <core-datatable :loaded="loaded" v-bind:columns.sync=columns :showFooter="true" :items="passesList"
                      v-bind:filter.sync="filter" :totalCount="passesTotalCount">
        <template #type="{ item }">
          {{ item.type === 1 ? $t('ordered_passes.active_one') :  $t('ordered_passes.active_two') }}
        </template>
        <template #ts="{ item }">
          {{ null | formatDateUntilTo(item.ts_start, getTimezone(item.location_id)) }}
          <span>-</span>
          {{ null | formatDateUntilTo(item.ts_end, getTimezone(item.location_id)) }}
        </template>
        <template #created_at="{ item }">
          <span v-if="item.created_at">{{item.created_at | formatDate}}</span>
          <span v-else>-</span>
        </template>
        <template #status="{ item, deepFind, getValuesItem, column }">
          <span class="label label-lg label-inline"
                :class="'label-light-' + getValuesItem(item.status, column).class">
            {{$t(`ordered_passes.status.${getValuesItem(item.status, column).name}`)}}
          </span>
        </template>
      </core-datatable>
      <!--end: Datatable-->
    </div>
    <!--begin::Order Pass Modal-->
    <OrderPassModal
        :showPassRequestModal="showPassRequestModal"
        @updatePassRequestModal="updatePassRequestModal"/>
    <!--end::Order Pass Modal-->
  </div>
</template>


<script>
import {mapGetters} from 'vuex'
import moment from 'moment-timezone'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
let cookie = require('vue-cookie');

import OrderPassModal from "../../../components/core/OrderPassModal";

export default {
  components: { DateRangePicker, OrderPassModal },
  computed: {
    ...mapGetters({
      integrations: 'main/integrations',
      coworkingById: 'spaces/coworkingById',
      passesList: 'passes/list',
      passesPage: 'passes/page',
      passesPerPage: 'passes/perPage',
      passesTotalCount: 'passes/totalCount',
      locationList: 'locationOrder/list',
      loaded: 'passes/loaded',
    }),
  },
  data() {
    return {
      showPassRequestModal: false,
      dateRange: {},
      selected: {},
      passTypeList: [
        { id: 1, name: cookie.get('locale') === 'ru' ? 'Разовый' : 'One-time' },
        { id: 2, name: cookie.get('locale') === 'ru' ? 'Временный' : 'Temporary' }
      ],
      statuses: [
        { id: 1, name: 'New' },
        { id: 2, name: 'Active' },
        { id: 3, name: 'Canceled' },
        { id: 4, name: 'Used' }
      ],
      columns: [
        { text: 'ordered_passes.columns.order', value: 'id', key: 'id' },
        { text: 'ordered_passes.columns.type', slot: 'type', key: 'type' },
        { text: 'ordered_passes.columns.period', slot: 'ts', key: 'ts_start', sort: false },
        { text: 'ordered_passes.columns.where', value: 'comment', key: 'comment', sort: false },
        { text: 'ordered_passes.columns.created_at', slot: 'created_at', key: 'created_at' },
        { text: 'ordered_passes.columns.status', slot: 'status', key: 'status', values: [
            { id: 1, name: 'New', class: 'info' },
            { id: 2, name: 'Active', class: 'success' },
            { id: 3, name: 'Canceled', class: 'danger' },
            { id: 4, name: 'Used', class: 'warning' }
          ]},
      ],
      filter: {
        sort: [],
        type: '',
        location_id: '',
        status: '',
        date_start_from: '',
        date_start_to: '',
        page: 1,
        'per-page': 20,
      }
    }
  },
  mounted() {
    this.$store.dispatch('locationOrder/GetData').then(() => {
      if (this.integrations && this.integrations.indexOf('integration_otp') === -1) {
        this.$router.push('/');
      } else {
        this.fetch()
      }
    })
  },
  watch: {
    columns: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
  },
  methods: {
    getTimezone(locationId) {
      if (this.locationList && this.locationList.length > 0) {
        const location = this.locationList.find(item => {
          return item.id === locationId;
        })
        return location && location.city ? location.city.timezone : 'UTC';
      } else {
        return 'Europe/Moscow'
      }
    },
    clickDataRangeCencel(data) {
      this.dateRange = {}
      data.rangeText = ''
      this.filter.date_start_from  = ''
      this.filter.date_start_to  = ''
      data.clickCancel()
    },
    updateDataRange(params) {
      // this.filter.date_start_from  = moment.unix(params.startDate).format('X') / 1000
      // this.filter.date_start_to  = moment.unix(params.endDate).format('X') / 1000
      this.filter.date_start_from  = moment(params.startDate).format('YYYY-MM-DD')
      this.filter.date_start_to  = moment(params.endDate).format('YYYY-MM-DD')
    },
    updatePassRequestModal(value) {
      this.showPassRequestModal = value;
      this.$store.dispatch('passes/GetData', this.filter)
    },
    fetch() {
      for (let i in this.columns) {
        switch(this.columns[i].sort) {
          case 1:
            this.filter.sort = this.columns[i].key
            break;
          case 2:
            this.filter.sort = '-'+this.columns[i].key
            break;
        }
      }
      this.filter.location_id = this.coworkingById.id;
      this.$store.dispatch('passes/GetData', this.filter)
    },
    visibilityChange(evt, hidden){
      if (!hidden) {
        this.fetch();
      }
    }
  }
}
</script>

<style>

</style>
